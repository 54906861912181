import DiagnosticIcon from "@mui/icons-material/BugReport";
import FirmwareUpdateIcon from "@mui/icons-material/SystemUpdate";
import {
  AppBar,
  Box,
  ButtonBase,
  Toolbar,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { NavPath } from "./Types";
import config_data from "./config.json";

function NavBar() {
  const StyledButton = styled(ButtonBase)(({ theme }) => ({
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    backgroundColor: "transparent",
    transition: "background-color 0.2s",
    "&:hover": {
      backgroundColor: alpha("#ffffff", 0.2),
    },
    flexBasis: "auto",
    flexShrink: 1,
    flexGrow: 0,
    flex: "0 0 auto",
  }));

  const WrappedToolbar = styled(Toolbar)({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  });

  const ButtonBox = styled(Box)({
    flex: "0 1 auto",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
  });

  const TitleBox = styled(Box)(({ theme }) => ({
    flex: "1 1 auto",
    textAlign: "left",
    order: -1,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  }));

  const navPaths: NavPath[] = [
    {
      path: "/firmware-update",
      title: (env: string) => `HEOS Firmware Update [${env}]`,
      icon: <FirmwareUpdateIcon />,
      label: "Firmware Update",
    },
    {
      path: "/device-diagnostics",
      title: (env: string) => `HEOS Device Diagnostic [${env}]`,
      icon: <DiagnosticIcon />,
      label: "Diagnostic",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const currentTitle = navPaths
    .find((navPath) => navPath.path === location.pathname)
    ?.title(config_data.ENV);

  return (
    <AppBar position="static">
      <WrappedToolbar>
        <TitleBox>
          <Typography variant="h6">{currentTitle}</Typography>
        </TitleBox>

        <ButtonBox>
          {navPaths.map((navPath) => (
            <StyledButton
              key={navPath.path}
              onClick={() => navigate(navPath.path)}
              style={{
                backgroundColor:
                  location.pathname === navPath.path
                    ? alpha("#ffffff", 0.2)
                    : "transparent",
              }}
            >
              {navPath.icon}
              {navPath.label}
            </StyledButton>
          ))}
        </ButtonBox>
      </WrappedToolbar>
    </AppBar>
  );
}

export default NavBar;
