import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import ConfigApp from "./ConfigApp";
import DiagnosticApp from "./DiagnosticApp";
import NavBar from "./NavBar";
import config_data from "./config.json";

// Load outside the App to ensure it only loads once during module imports.
Amplify.configure(config_data.Amplify);

function App() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);

  // authenticate
  React.useEffect(() => {
    const checkUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (err) {
        localStorage.setItem("returnURL", window.location.pathname);

        // user is not authenticated, initiate SSO sign in
        // @ts-ignore
        Auth.federatedSignIn({ provider: "SSO" });
      } finally {
        setLoading(false);
      }
    };

    // check user on mount
    if (!!(config_data.Amplify as any).Auth) {
      console.log("authenticating");
      checkUser();
    } else {
      console.log("passing through");
      setIsAuthenticated(true);
      setLoading(false);
    }
  }, []);

  function LoadingComponent() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CircularProgress size={80} color="primary" />
        <Typography mt={2} variant="h6">
          Authenticating...
        </Typography>
      </Box>
    );
  }

  function RedirectToFirmwareUpdate() {
    const navigate = useNavigate();

    React.useEffect(() => {
      const returnUrl = localStorage.getItem("returnURL");
      const navigateTo = returnUrl === "/" ? "/firmware-update" : returnUrl;
      navigate(navigateTo || "/firmware-update");
    }, [navigate]);

    return null;
  }

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div>
      {isAuthenticated ? (
        <Router>
          <NavBar />
          <Container maxWidth={false} style={{ padding: 0 }}>
            <Routes>
              <Route path="/firmware-update" element={<ConfigApp />} />
              <Route path="/device-diagnostics" element={<DiagnosticApp />} />
              <Route path="/" element={<RedirectToFirmwareUpdate />} />
            </Routes>
          </Container>
        </Router>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
}

export default App;
