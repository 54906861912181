/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listStreamConfigs = /* GraphQL */ `
  query ListStreamConfigs {
    listStreamConfigs {
      uid
      name
      priority
      owner
      createdAt
      updatedAt
      status
      filters {
        devices {
          included {
            deviceId
            household
            __typename
          }
          excluded {
            deviceId
            household
            __typename
          }
          __typename
        }
        models {
          included {
            modelId
            household
            __typename
          }
          excluded {
            modelId
            household
            __typename
          }
          __typename
        }
        __typename
      }
      projections {
        modelId
        version
        versionCutoff
        imageId
        __typename
      }
      minimumVersion
      maximumVersion
      rolloutTargetPercentage
      rolloutTargetPercentagePrev
      rolloutInclusionRate
      __typename
    }
  }
`;
export const listModels = /* GraphQL */ `
  query ListModels {
    listModels {
      id
      name
      alias
      platform
      hw
      dev
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages {
    listImages {
      id
      filename
      md5sum
      version
      versionCutoff
      hw
      dev
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIncludedDevices = /* GraphQL */ `
  query ListIncludedDevices($streamId: ID!) {
    listIncludedDevices(streamId: $streamId)
  }
`;
export const listExcludedDevices = /* GraphQL */ `
  query ListExcludedDevices($streamId: ID!) {
    listExcludedDevices(streamId: $streamId)
  }
`;
export const listPartialRolloutWaitListDevices = /* GraphQL */ `
  query ListPartialRolloutWaitListDevices($streamId: ID!) {
    listPartialRolloutWaitListDevices(streamId: $streamId)
  }
`;
