import React, { FunctionComponent } from "react";
import { StreamConfig } from "./Types";

import { DataGrid, GridToolbarContainer, GridActionsCellItem, GridToolbarExport, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CallMissedIcon from "@mui/icons-material/CallMissed";
import { Button, Tooltip } from "@mui/material";
import Papa from "papaparse";

type ConfigsListProps = {
    rows: StreamConfig[];
    onSelect: (config?: StreamConfig) => void;
    onListIncluded: (config: StreamConfig) => Promise<string[]>;
    onListWaiting: (config: StreamConfig) => Promise<string[]>;
    onListExcluded: (config: StreamConfig) => Promise<string[]>;
    onDelete: (config: StreamConfig) => void;
    loading?: boolean;
};

interface ConfigsListEditToolbarProps {
    onAdd: () => void;
}

const ConfigsListEditToolbar: FunctionComponent<ConfigsListEditToolbarProps> = (props: ConfigsListEditToolbarProps) => {
    const { onAdd } = props;

    return (
        <GridToolbarContainer>
            <Button startIcon={<AddIcon />} onClick={onAdd}>
                Add
            </Button>
            <GridToolbarExport
                startIcon={<FileDownloadIcon />}
                printOptions={{ disableToolbarButton: true }}
                csvOptions={{
                    fileName: "configs",
                    delimiter: ",",
                    utf8WithBom: true,
                }}
            />
        </GridToolbarContainer>
    );
};

export const ConfigsList: FunctionComponent<ConfigsListProps> = (props: ConfigsListProps) => {
    const { rows, onSelect, onListIncluded, onListWaiting, onListExcluded, onDelete, loading } = props;
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const downloadCSV = (filename: string, data: string) => {
        const download = document.createElement("a");
        download.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
        download.setAttribute("download", filename);
        download.style.display = "none";
        document.body.appendChild(download);
        download.click();
        download.remove();
    };

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: "status",
            headerName: "Status",
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: "priority",
            headerName: "Priority",
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 135,
            cellClassName: "actions",
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Included devices">
                                <DownloadDoneIcon />
                            </Tooltip>
                        }
                        label="Inclusions"
                        onClick={async () => {
                            const config = rows.find((row) => row.uid === id.toString());
                            if (config) {
                                const data = await onListIncluded(config);
                                if (data?.length) {
                                    const csv = Papa.unparse({ fields: ["deviceId"], data: data.map((mac: string) => [mac]) }, { quotes: true });
                                    downloadCSV(id.toString() + "_included.txt", csv);
                                }
                            }
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Wait list devices">
                                <HourglassTopIcon />
                            </Tooltip>
                        }
                        label="Wait list"
                        onClick={async () => {
                            const config = rows.find((row) => row.uid === id.toString());
                            if (config) {
                                const data = await onListWaiting(config);
                                if (data?.length) {
                                    const csv = Papa.unparse({ fields: ["deviceId"], data: data.map((mac: string) => [mac]) }, { quotes: true });
                                    downloadCSV(id.toString() + "_wait_list.txt", csv);
                                }
                            }
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Excluded devices">
                                <CallMissedIcon />
                            </Tooltip>
                        }
                        label="Exclusions"
                        onClick={async () => {
                            const config = rows.find((row) => row.uid === id.toString());
                            if (config) {
                                const data = await onListExcluded(config);
                                if (data?.length) {
                                    const csv = Papa.unparse({ fields: ["deviceId"], data: data.map((mac: string) => [mac]) }, { quotes: true });
                                    downloadCSV(id.toString() + "_excluded.txt", csv);
                                }
                            }
                        }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Delete">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={() => {
                            let config = rows.find((row) => row.uid === id.toString());
                            if (config) {
                                onDelete(config);
                            }
                        }}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <DataGrid
            loading={loading}
            rows={rows || []}
            getRowId={(row) => row.uid}
            columns={columns}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(selected: GridRowSelectionModel) => {
                setSelectionModel(selected);
                if (selected?.length) {
                    let config = rows.find((row) => row.uid === selected[0].toString());
                    if (config) {
                        onSelect(config);
                        return;
                    }
                }
                onSelect(undefined);
            }}
            slots={{
                toolbar: ConfigsListEditToolbar,
            }}
            slotProps={{
                toolbar: {
                    onAdd: () => {
                        setSelectionModel([]);
                        onSelect(undefined);
                    },
                    printOptions: { disableToolbarButton: true },
                },
            }}
            autoPageSize
            initialState={{
                sorting: {
                    sortModel: [{ field: "priority", sort: "desc" }],
                },
            }}
        />
    );
};
