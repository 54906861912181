async function sha1(data: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(data); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-1", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
}

export async function getSignature(
  method: string,
  secret: string,
  data: { [key: string]: any }
): Promise<string> {
  const keys: string[] = [];
  for (const key in data) {
    if (data.hasOwnProperty(key) && key !== "sk") {
      keys.push(key);
    }
  }
  keys.sort();

  let sig = method;
  for (let i = 0; i < keys.length; i++) {
    sig += keys[i] + data[keys[i]];
  }

  sig += secret;

  return sha1(sig);
}
