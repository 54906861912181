import AssignmentIcon from "@mui/icons-material/Assignment";

import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { API } from "aws-amplify";
import React from "react";
import { Link } from "react-router-dom";
import { DiagnosticsConfig } from "./Types";
import config_data from "./config.json";
import { getSignature } from "./requestSigning";

const requestDiagnostic = async (deviceId: string) => {
  const key = config_data.CloudControlApiKey.key;
  const secret = config_data.CloudControlApiKey.secret;
  const queryStringParameters: Record<string, string> = {
    origin: "CLOUD",
    key,
  };

  const sig = await getSignature("POST", secret, queryStringParameters);
  queryStringParameters.sig = sig;

  try {
    // All Device ID's must be prefixed for legacy reasons so the ASN can find
    // the connection.
    const response = await API.post(
      "CloudControl",
      `/control/device/player/3C424E4F${deviceId}/diagnostic`,
      { queryStringParameters },
    );
    return response;
  } catch (error) {
    console.error("Error requesting logs data: ", error);
    return console.error("Error requesting logs data: ", error);
  }
};

type ActionButtonProps = {
  isLoading: boolean;
  onClick: () => void;
  label: string;
  icon: React.ReactNode;
};

function DiagnosticApp() {
  React.useEffect(() => {
    document.title = "HEOS Device Diagnostic Page";
  }, []);

  const mintConfig: DiagnosticsConfig = {
    deviceId: "",
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [inEdit, setInEdit] = React.useState<DiagnosticsConfig>(mintConfig);
  const [hasError, setHasError] = React.useState<
    { [key: string]: any } | undefined
  >(undefined);
  const [hasSuccess, setHasSuccess] = React.useState<boolean>(false);

  const submitDiagnosticRequest = async () => {
    setIsLoading(true);
    setHasError(undefined);
    setHasSuccess(false);

    if (inEdit.deviceId) {
      const resp = await requestDiagnostic(inEdit.deviceId);
      resp.Error ? setHasError(resp.Error) : setHasError(undefined);
      if (!resp.Error) {
        resp.Status?.Text === "fail"
          ? setHasError(resp)
          : setHasError(undefined);
      }
      resp.Status?.Text === "ok" ? setHasSuccess(true) : setHasSuccess(false);
    }

    setIsLoading(false);
  };

  const resetForm = () => {
    setHasError(undefined);
    setHasSuccess(false);
    setInEdit({ deviceId: "" });
    setIsLoading(false);
  };

  const ActionButton: React.FC<ActionButtonProps> = ({
    isLoading,
    onClick,
    label,
    icon,
  }) => (
    <Button
      variant="contained"
      startIcon={isLoading ? <CircularProgress size={24} /> : icon}
      onClick={onClick}
      disabled={isLoading}
      fullWidth
    >
      {label}
    </Button>
  );

  // feature env is inconsistant with the other env

  const adminPageLink = `${config_data.AdminPageUrl}/users/device/?q=${inEdit.deviceId}`;
  const adminPageLog = `${config_data.AdminPageUrl}/admin/feedback/log/?q=${inEdit.deviceId}`;

  return (
    <Box
      sx={{
        height: "100vh",
        width: "98vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography align="center" padding={1} variant="h4"></Typography>
      <Typography align="center" padding={1} variant="h4"></Typography>
      <Grid
        container
        columns={1}
        spacing={2}
        direction="column"
        sx={{ padding: 2, height: "100%", width: { xs: "100%", md: "50%" } }}
      >
        <Grid item>
          <TextField
            required
            label="Device/Machine ID"
            value={inEdit.deviceId || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let updated = { deviceId: event.target.value.trim() };
              setInEdit((inEdit) => ({ ...inEdit, ...updated }));
              setHasError(undefined);
            }}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Grid>
        {inEdit.deviceId && !hasSuccess && (
          <>
            <Grid item>
              <Grid
                item
                sx={{
                  textDecoration: "none",
                  transition: "0.3s",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
                  borderRadius: "4px",
                }}
              >
                <Card
                  component={Link}
                  to={adminPageLink}
                  target="_blank"
                  sx={{ textDecoration: "none" }}
                >
                  <CardContent>View Device</CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item>
              <ActionButton
                isLoading={isLoading}
                onClick={submitDiagnosticRequest}
                label="Request Logs"
                icon={<AssignmentIcon />}
              />
            </Grid>
          </>
        )}
        <Grid item>
          {hasError && (
            <Alert severity="error" variant="filled">
              Log request failed with
              {hasError?.Status?.Result || hasError?.Message}
            </Alert>
          )}
        </Grid>
        {hasSuccess && (
          <>
            <Grid item>
              <Alert severity="success" variant="filled">
                Log request completed successfully. You can view the pending log
                upload below.
              </Alert>
            </Grid>
            <Grid item>
              <Grid
                item
                sx={{
                  transition: "0.3s",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
                  borderRadius: "4px",
                }}
              >
                <Card
                  component={Link}
                  to={adminPageLog}
                  target="_blank"
                  sx={{ textDecoration: "none" }}
                >
                  <CardContent>View Logs</CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item>
              <ActionButton
                isLoading={false}
                onClick={resetForm}
                label="Reset Form"
                icon={<AssignmentIcon />}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default DiagnosticApp;
