/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addStreamConfig = /* GraphQL */ `
  mutation AddStreamConfig($input: EditStreamConfigInput) {
    addStreamConfig(input: $input) {
      uid
      name
      priority
      owner
      createdAt
      updatedAt
      status
      filters {
        devices {
          included {
            deviceId
            household
            __typename
          }
          excluded {
            deviceId
            household
            __typename
          }
          __typename
        }
        models {
          included {
            modelId
            household
            __typename
          }
          excluded {
            modelId
            household
            __typename
          }
          __typename
        }
        __typename
      }
      projections {
        modelId
        version
        versionCutoff
        imageId
        __typename
      }
      minimumVersion
      maximumVersion
      rolloutTargetPercentage
      rolloutTargetPercentagePrev
      rolloutInclusionRate
      __typename
    }
  }
`;
export const updateStreamConfig = /* GraphQL */ `
  mutation UpdateStreamConfig($uid: ID!, $input: EditStreamConfigInput) {
    updateStreamConfig(uid: $uid, input: $input) {
      uid
      name
      priority
      owner
      createdAt
      updatedAt
      status
      filters {
        devices {
          included {
            deviceId
            household
            __typename
          }
          excluded {
            deviceId
            household
            __typename
          }
          __typename
        }
        models {
          included {
            modelId
            household
            __typename
          }
          excluded {
            modelId
            household
            __typename
          }
          __typename
        }
        __typename
      }
      projections {
        modelId
        version
        versionCutoff
        imageId
        __typename
      }
      minimumVersion
      maximumVersion
      rolloutTargetPercentage
      rolloutTargetPercentagePrev
      rolloutInclusionRate
      __typename
    }
  }
`;
export const deleteStreamConfig = /* GraphQL */ `
  mutation DeleteStreamConfig($uid: ID!) {
    deleteStreamConfig(uid: $uid)
  }
`;
export const clearPartialRolloutWaitList = /* GraphQL */ `
  mutation ClearPartialRolloutWaitList($streamId: ID!) {
    clearPartialRolloutWaitList(streamId: $streamId)
  }
`;
