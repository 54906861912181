import { API, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { randomId } from "@mui/x-data-grid-generator";
import React from "react";
import { ConfigFilterDevices } from "./ConfigFilterDevices";
import { ConfigFilterModels } from "./ConfigFilterModels";
import { ConfigProjections } from "./ConfigProjections";
import { ConfigsList } from "./ConfigsList";
import {
  DeviceModel,
  FilterDeviceCortege,
  FilterModelCortege,
  FwImage,
  ProjectionCortege,
  Status,
  StreamConfig,
} from "./Types";
import {
  addStreamConfig as addConfigMutation,
  clearPartialRolloutWaitList as clearWaitListMutation,
  deleteStreamConfig as deleteConfigMutation,
  updateStreamConfig as updateConfigMutation,
} from "./graphql/mutations";
import {
  listExcludedDevices,
  listImages,
  listIncludedDevices,
  listModels,
  listPartialRolloutWaitListDevices,
  listStreamConfigs,
} from "./graphql/queries";

enum EditingPaneTab {
  FILTERS = "Criteria",
  PROJECTIONS = "Images",
}

function ConfigApp() {
  React.useEffect(() => {
    document.title = "HEOS Firmware Update configuration";
  }, []);

  // get configs
  const [configs, setConfigs] = React.useState<StreamConfig[]>([]);
  const [configsLoading, setConfigsLoading] = React.useState<boolean>(false);
  async function fetchConfigs() {
    setConfigsLoading(true);
    const response = (await API.graphql({
      query: listStreamConfigs,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as { data: any };
    const db_data: any[] = response.data.listStreamConfigs;
    const massaged_data: StreamConfig[] = db_data.map((line: any) => {
      // create empty fields for optionals fo UI can use them for controlled components
      line?.filters || (line.filters = {});
      line.filters?.models || (line.filters.models = {});
      line.filters.models?.included || (line.filters.models.included = []);
      line.filters.models?.excluded || (line.filters.models.excluded = []);
      line.filters?.devices || (line.filters.devices = {});
      line.filters.devices?.included || (line.filters.devices.included = []);
      line.filters.devices?.excluded || (line.filters.devices.excluded = []);
      line?.projections || (line.projections = []);
      line?.minimumVersion || (line.minimumVersion = "");
      line?.maximumVersion || (line.maximumVersion = "");
      // Keep track of the initial target percentage initially retrieved from the DB,
      // so that we can later calculate the inclusion rate correctly.
      line.rolloutTargetPercentagePrev = line?.rolloutTargetPercentage;
      line?.rolloutTargetPercentagePrev ||
        (line.rolloutTargetPercentagePrev = 0);
      line?.rolloutTargetPercentage || (line.rolloutTargetPercentage = 100);
      line?.rolloutInclusionRate || (line.rolloutInclusionRate = 1);
      // massage existing fields for DataGrid
      line.filters.models.included = line.filters.models.included.map(
        (fm: any) => ({ id: randomId(), ...fm }),
      );
      line.filters.models.excluded = line.filters.models.excluded.map(
        (fm: any) => ({ id: randomId(), ...fm }),
      );
      line.filters.devices.included = line.filters.devices.included.map(
        (fd: any) => ({ id: randomId(), ...fd }),
      );
      line.filters.devices.excluded = line.filters.devices.excluded.map(
        (fd: any) => ({ id: randomId(), ...fd }),
      );
      line.projections = line.projections.map((proj: any) => ({
        id: randomId(),
        ...proj,
      }));
      return line;
    });
    setConfigs(massaged_data);
    console.log("fetched %s configs", response.data.listStreamConfigs.length);
    setConfigsLoading(false);
  }
  React.useEffect(() => {
    fetchConfigs();
  }, []);
  async function fetchIncludedDevices(configId: string) {
    const response = (await API.graphql({
      query: listIncludedDevices,
      variables: { streamId: configId },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as { data: any };
    const db_data = response.data.listIncludedDevices;
    if (db_data) {
      console.log(
        "fetched %s devices included for config %s",
        db_data.length,
        configId,
      );
      return db_data;
    }
    console.log("no devices included for config %s", configId);
    return [];
  }
  async function fetchWaitingDevices(configId: string) {
    const response = (await API.graphql({
      query: listPartialRolloutWaitListDevices,
      variables: {
        streamId: configId,
      },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as { data: any };
    const db_data = response.data.listPartialRolloutWaitListDevices;
    if (db_data) {
      console.log(
        "fetched %s devices in wait list for config %s",
        db_data.length,
        configId,
      );
      return db_data;
    }
    console.log("no devices in wait list for config %s", configId);
    return [];
  }
  async function fetchExcludedDevices(configId: string) {
    const response = (await API.graphql({
      query: listExcludedDevices,
      variables: { streamId: configId },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as { data: any };
    const db_data = response.data.listExcludedDevices;
    if (db_data) {
      console.log(
        "fetched %s devices excluded for config %s",
        db_data.length,
        configId,
      );
      return db_data;
    }
    console.log("no devices excluded for config %s", configId);
    return [];
  }

  // get models
  const [models, setModels] = React.useState<DeviceModel[]>([]);
  const [modelsLoading, setModelsLoading] = React.useState<boolean>(false);
  async function fetchModels() {
    setModelsLoading(true);
    const response = (await API.graphql({
      query: listModels,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as {
      data: any;
    };
    setModels(response.data.listModels);
    console.log("fetched %s models", response.data.listModels.length);
    setModelsLoading(false);
  }
  React.useEffect(() => {
    fetchModels();
  }, []);

  // get images
  const [images, setImages] = React.useState<FwImage[]>([]);
  const [imagesLoading, setImagesLoading] = React.useState<boolean>(false);
  async function fetchImages() {
    setImagesLoading(true);
    const response = (await API.graphql({
      query: listImages,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as {
      data: any;
    };
    setImages(response.data.listImages);
    console.log("fetched %s images", response.data.listImages.length);
    setImagesLoading(false);
  }
  React.useEffect(() => {
    fetchImages();
  }, []);

  const mintConfig: StreamConfig = {
    uid: "",
    name: "",
    priority: 1,
    status: Status.ACTIVE,
    filters: {
      models: {
        included: [],
        excluded: [],
      },
      devices: {
        included: [],
        excluded: [],
      },
    },
    projections: [],
    minimumVersion: "",
    maximumVersion: "",
    rolloutTargetPercentage: 100,
    // For a new config, we need to treat it like it hasn't been rolled out
    // to any devices yet, so that the initial inclusion rate calculation
    // will be correct
    rolloutTargetPercentagePrev: 0,
    rolloutInclusionRate: 1,
  };
  const [inEdit, setInEdit] = React.useState<StreamConfig>(mintConfig);

  // configs management
  function removeGraphQLTypename(obj: any): void {
    if (typeof obj !== "object" || obj === null) return;
    if ("__typename" in obj) {
      delete obj["__typename"];
    }
    for (let key in obj) {
      removeGraphQLTypename(obj[key]);
    }
  }
  function brushUpStreamConfig(victim: StreamConfig): any {
    // deep copy done JS way (facepalm here)
    let data = JSON.parse(JSON.stringify(victim));
    // remove empty fields
    if (data.filters.models.included.length === 0) {
      delete data.filters.models.included;
    } else {
      data.filters.models.included = data.filters.models.included.map(
        (fm: any) => {
          delete fm.id;
          delete fm.isNew;
          return fm;
        },
      );
    }
    if (data.filters.models.excluded.length === 0) {
      delete data.filters.models.excluded;
    } else {
      data.filters.models.excluded = data.filters.models.excluded.map(
        (fm: any) => {
          delete fm.id;
          delete fm.isNew;
          return fm;
        },
      );
    }
    if (data.filters.devices.included.length === 0) {
      delete data.filters.devices.included;
    } else {
      data.filters.devices.included = data.filters.devices.included.map(
        (fd: any) => {
          delete fd.id;
          delete fd.isNew;
          return fd;
        },
      );
    }
    if (data.filters.devices.excluded.length === 0) {
      delete data.filters.devices.excluded;
    } else {
      data.filters.devices.excluded = data.filters.devices.excluded.map(
        (fd: any) => {
          delete fd.id;
          delete fd.isNew;
          return fd;
        },
      );
    }
    if (["included", "excluded"].every((f) => !(f in data.filters.models)))
      delete data.filters.models;
    if (["included", "excluded"].every((f) => !(f in data.filters.devices)))
      delete data.filters.devices;
    if (["models", "devices"].every((f) => !(f in data.filters)))
      delete data.filters;
    if (data.projections.length === 0) {
      delete data.projections;
    } else {
      data.projections = data.projections.map((proj: any) => {
        delete proj.id;
        delete proj.isNew;
        // version comes from image selected
        proj.version = images.find((image) => image.id === proj.imageId)?.version;
        proj.versionCutoff = proj.versionCutoff.trim();
        return proj;
      });
    }
    if (data.minimumVersion === "") delete data.minimumVersion;
    if (data.maximumVersion === "") delete data.maximumVersion;
    if (data.rolloutTargetPercentage === "")
      delete data.rolloutTargetPercentage;
    return data;
  }
  function finaliseRolloutChanges(data: StreamConfig): StreamConfig {
    let prev_target = data?.rolloutTargetPercentagePrev ?? 0;
    let new_target = data?.rolloutTargetPercentage ?? 100;
    let inclusion_rate = 1;
    if (prev_target <= 100) {
      inclusion_rate = (new_target - prev_target) / (100 - prev_target);
    }
    data.rolloutInclusionRate = inclusion_rate;
    console.log(
      "calculated rollout inclusion rate %f for target percentage %f% -> %f%",
      inclusion_rate,
      prev_target,
      new_target,
    );
    return data;
  }
  async function clearRolloutWaitList(data: StreamConfig) {
    let prev_target = data?.rolloutTargetPercentagePrev ?? 0;
    let new_target = data?.rolloutTargetPercentage ?? 100;
    if (new_target !== prev_target) {
      // We've updated the rollout, so give devices in the wait list a
      // chance to become enrolled
      if (data?.uid) {
        console.log(
          "clearing wait list for stream %s [%s]",
          data.name,
          data.uid,
        );
        await API.graphql({
          query: clearWaitListMutation,
          variables: { streamId: data.uid },
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        console.log(
          "wait list cleared for stream %s [%s]",
          data.name,
          data.uid,
        );
      }
    } else {
      console.log(
        "No rollout changes for stream  %s [%s], leaving wait list unchanged",
        data.name,
        data.uid,
      );
    }
  }
  async function addConfig() {
    console.log("adding new config");
    let data = brushUpStreamConfig(inEdit);
    data = finaliseRolloutChanges(data);
    delete data.uid;
    removeGraphQLTypename(data);
    const newConfig = (await API.graphql({
      query: addConfigMutation,
      variables: { input: data },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as { data: any };
    console.log(
      "config %s [%s] created",
      newConfig.data.addStreamConfig.name,
      newConfig.data.addStreamConfig.uid,
    );
    fetchConfigs();
  }
  async function updateConfig() {
    console.log("updating config %s [%s]", inEdit.name, inEdit.uid);
    let data = brushUpStreamConfig(inEdit);
    data = finaliseRolloutChanges(data);
    delete data.uid;
    delete data.owner;
    delete data.createdAt;
    delete data.updatedAt;
    removeGraphQLTypename(data);
    console.log(data);
    const newConfig = (await API.graphql({
      query: updateConfigMutation,
      variables: { uid: inEdit.uid, input: data },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    })) as { data: any };
    console.log(
      "config %s [%s] updated",
      newConfig.data.updateStreamConfig.name,
      newConfig.data.updateStreamConfig.uid,
    );
    console.log(newConfig.data)
    fetchConfigs();
    await clearRolloutWaitList(newConfig.data.updateStreamConfig);
  }
  async function deleteConfig(configId: string) {
    await API.graphql({
      query: deleteConfigMutation,
      variables: { uid: configId },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    });
    console.log("config %s deleted", configId);
    fetchConfigs();
  }

  const [filtersProjectionsTab, setFiltersProjectionsTab] =
    React.useState<EditingPaneTab>(EditingPaneTab.FILTERS);

  return (
    <div>
      <Box
        sx={{
          height: "100vh",
          width: "98vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          columns={12}
          spacing={1}
          padding={1}
          paddingLeft={3}
          height="100%"
          width="100%"
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography align="center" padding={1} variant="h6">
              Streams
            </Typography>
            <ConfigsList
              rows={configs}
              loading={configsLoading}
              onSelect={(config) =>
                config === undefined ? setInEdit(mintConfig) : setInEdit(config)
              }
              onListIncluded={(config) => fetchIncludedDevices(config.uid)}
              onListWaiting={(config) => fetchWaitingDevices(config.uid)}
              onListExcluded={(config) => fetchExcludedDevices(config.uid)}
              onDelete={(config) => deleteConfig(config.uid)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={8}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography align="center" padding={1} variant="h6">
              {inEdit.uid ? "Selected Stream" : "New Stream"}
            </Typography>
            <Grid container columns={12} spacing={2} padding={1}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  label="uid"
                  value={inEdit.uid || "New uuid will be generated"}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Name"
                  value={inEdit.name || ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let updated = { name: event.target.value };
                    setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={Array.from(Array(99)).map((_, i) => i + 1)}
                  disableClearable
                  value={inEdit.priority}
                  getOptionLabel={(option) => option.toString()}
                  onChange={(_, newValue: number) => {
                    let updated = { priority: newValue };
                    setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Priority"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={Object.keys(Status)}
                  disableClearable
                  value={inEdit.status}
                  onChange={(_, newValue: string) => {
                    let updated = {
                      status: Status[newValue as keyof typeof Status],
                    };
                    setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tabs
                  value={filtersProjectionsTab}
                  onChange={(_, newValue: EditingPaneTab) => {
                    setFiltersProjectionsTab(newValue);
                  }}
                  centered
                >
                  {Object.entries(EditingPaneTab).map(([key, value]) => (
                    <Tab
                      key={key}
                      label={value}
                      value={EditingPaneTab[key as keyof typeof EditingPaneTab]}
                    />
                  ))}
                </Tabs>
              </Grid>
            </Grid>
            {filtersProjectionsTab === EditingPaneTab.FILTERS && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                  container
                  columns={12}
                  rowSpacing={6}
                  columnSpacing={2}
                  padding={1}
                >
                  <Grid item xs={12} lg={6} minHeight={400}>
                    <Typography
                      align="center"
                      alignContent="center"
                      padding={1}
                      variant="subtitle1"
                    >
                      Model Inclusions
                    </Typography>
                    <ConfigFilterModels
                      configId={inEdit.uid}
                      models={models}
                      rows={inEdit.filters.models.included}
                      onRowsChange={(newRows: FilterModelCortege[]) => {
                        setInEdit((prev: StreamConfig) => ({
                          ...prev,
                          filters: {
                            models: {
                              included: newRows,
                              excluded: prev.filters.models.excluded,
                            },
                            devices: prev.filters.devices,
                          },
                        }));
                      }}
                      loading={modelsLoading}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} minHeight={400}>
                    <Typography
                      align="center"
                      alignContent="center"
                      padding={1}
                      variant="subtitle1"
                    >
                      Model Exclusions
                    </Typography>
                    <ConfigFilterModels
                      configId={inEdit.uid}
                      models={models}
                      rows={inEdit.filters.models.excluded}
                      onRowsChange={(newRows: FilterModelCortege[]) => {
                        setInEdit((prev: StreamConfig) => ({
                          ...prev,
                          filters: {
                            models: {
                              included: prev.filters.models.included,
                              excluded: newRows,
                            },
                            devices: prev.filters.devices,
                          },
                        }));
                      }}
                      loading={modelsLoading}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} minHeight={400}>
                    <Typography
                      align="center"
                      alignContent="center"
                      padding={1}
                      variant="subtitle1"
                    >
                      Device Inclusions
                    </Typography>
                    <ConfigFilterDevices
                      configId={inEdit.uid}
                      rows={inEdit.filters.devices.included}
                      onRowsChange={(newRows: FilterDeviceCortege[]) => {
                        setInEdit((prev: StreamConfig) => ({
                          ...prev,
                          filters: {
                            devices: {
                              included: newRows,
                              excluded: prev.filters.devices.excluded,
                            },
                            models: prev.filters.models,
                          },
                        }));
                      }}
                      loading={modelsLoading}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} minHeight={400}>
                    <Typography
                      align="center"
                      alignContent="center"
                      padding={1}
                      variant="subtitle1"
                    >
                      Device Exclusions
                    </Typography>
                    <ConfigFilterDevices
                      configId={inEdit.uid}
                      rows={inEdit.filters.devices.excluded}
                      onRowsChange={(newRows: FilterDeviceCortege[]) => {
                        setInEdit((prev: StreamConfig) => ({
                          ...prev,
                          filters: {
                            devices: {
                              included: prev.filters.devices.included,
                              excluded: newRows,
                            },
                            models: prev.filters.models,
                          },
                        }));
                      }}
                      loading={modelsLoading}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="center"
                      alignContent="center"
                      padding={1}
                      variant="subtitle1"
                    />
                    <TextField
                      label="Minimum Version"
                      value={inEdit.minimumVersion || ""}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        let updated = { minimumVersion: event.target.value };
                        setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="center"
                      alignContent="center"
                      padding={1}
                      variant="subtitle1"
                    ></Typography>
                    <TextField
                      label="Maximum Version"
                      value={inEdit.maximumVersion || ""}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        let updated = { maximumVersion: event.target.value };
                        setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "-20px" }}>
                    <TextField
                      label="Partial rollout percentage"
                      value={inEdit.rolloutTargetPercentage ?? "100"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        let updated = {
                          rolloutTargetPercentage: Number(event.target.value),
                        };
                        setInEdit((inEdit) => ({ ...inEdit, ...updated }));
                      }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            {filtersProjectionsTab === EditingPaneTab.PROJECTIONS && (
              <Box height="100%" minHeight={400}>
                <ConfigProjections
                  configId={inEdit.uid}
                  models={models}
                  images={images}
                  rows={inEdit.projections}
                  onRowsChange={(newRows: ProjectionCortege[]) => {
                    console.log(newRows)
                    setInEdit((prev: StreamConfig) => ({
                      ...prev,
                      projections: newRows,
                    }));
                  }}
                  loading={modelsLoading || imagesLoading}
                />
              </Box>
            )}
            <Box sx={{ marginTop: "auto" }}>
              <Grid
                container
                columns={2}
                justifyContent="space-evenly"
                alignItems="center"
                padding={1}
              >
                <Grid item>
                  {inEdit.uid ? (
                    <Button
                      variant="contained"
                      startIcon={<UpgradeIcon />}
                      onClick={() => updateConfig()}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => addConfig()}
                    >
                      Add
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    disabled={!inEdit.uid}
                    onClick={() => deleteConfig(inEdit.uid)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ConfigApp;
