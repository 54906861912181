// NOTE: once Amplify's TS codegen is fixed, these types will be autogenerated
export enum Status {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DRYRUN = "DRYRUN",
}

export enum Platform {
  ANDROID = "Android",
  BRIDGE = "Bridge",
  EMBEDDED = "Embedded",
  IOS = "iOS",
  OSX = "OSX",
  WINDOWS = "Windows",
  X11 = "X11",
}

export interface DeviceModel {
  id: number;
  name: string;
  alias: string;
  platform: Platform;
  hw: string;
  dev?: boolean;
}

export interface FwImage {
  id: number;
  filename: string;
  md5sum: string;
  version: string;
  versionCutoff: string;
  createdAt: string;
  updatedAt: string;
  hw: string;
  dev?: boolean;
}

export interface StreamConfigFilterModel {
  modelId: number;
  household: boolean;
}

export interface StreamConfigFilterDevice {
  deviceId: string;
  household: boolean;
}

export interface StreamConfigProjection {
  modelId: number;
  // version: string;
  versionCutoff: string;
  imageId: number;
}

interface DataGridRowExtras {
  id: string;
  isNew?: boolean;
}
export interface FilterModelCortege
  extends StreamConfigFilterModel,
    DataGridRowExtras {}
export interface FilterDeviceCortege
  extends StreamConfigFilterDevice,
    DataGridRowExtras {}
export interface ProjectionCortege
  extends StreamConfigProjection,
    DataGridRowExtras {}

export interface StreamConfig {
  uid: string;
  name: string;
  priority: number;
  // createdAt: string;
  // updatedAt: string;
  status: Status;
  filters: {
    models: {
      included: FilterModelCortege[];
      excluded: FilterModelCortege[];
    };
    devices: {
      included: FilterDeviceCortege[];
      excluded: FilterDeviceCortege[];
    };
  };
  projections: ProjectionCortege[];
  minimumVersion: string;
  maximumVersion: string;
  rolloutTargetPercentage: number;
  rolloutTargetPercentagePrev: number;
  rolloutInclusionRate: number;
}

export interface DiagnosticsConfig {
  deviceId: string;
}

export interface NavPath {
  path: string;
  title: (env: string) => string;
  icon: React.ReactElement;
  label: string;
}
